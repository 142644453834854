<template>
  <div class="col-12">
    <div class="card">
      <slot name="start">
        <Toolbar class="tw-mb-8 tw-p-3">
          <template #start>
              <Button label="Tambah" icon="pi pi-plus"
                class="p-button-sm p-button-success tw-bg-blue-light"
                @click="navigateToCreate"
              />
          </template>
        </Toolbar>
      </slot>

      <div class="text-xl font-medium text-black mb-3">
        <p>Daftar Administrator</p>
      </div>
      
      <DataTable
        :value="users"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        v-model:filters="filters1"
        filterDisplay="menu"
        :filters="filters1"
        responsiveLayout="scroll"
        :globalFilterFields="['no', 'email', 'phone_number', 'status_name']"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="perPageOptions"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      >
        <template #header>
          <div class="flex justify-content-between flex-column sm:flex-row">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Hapus Filter"
              class="p-button-outlined mb-2"
              @click="clearFilter()"
            />
            <span class="p-input-icon-left mb-2">
              <i class="pi pi-search" />
              <InputText
                v-model="filters1['global'].value"
                placeholder="Kata kunci"
                style="width: 100%"
              />
            </span>
          </div>
        </template>
        <template #empty>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column field="no" header="No." style="min-width: 2rem">
          <template #body="{ data }">
            {{ data.no }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by number"
            />
          </template>
        </Column>
        <Column header="Email" filterField="email" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.email }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by email"
            />
          </template>
        </Column>
        <Column header="No. HP" filterField="phone_number" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.phone_number ? data.phone_number : `-` }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by No. HP"
            />
          </template>
        </Column>
        <Column header="Status" filterField="status_name" style="min-width: 6rem">
          <template #body="{ data }">
            <Tag
              :severity="severity(data.status)"
              :value="admin_status.find((e) => e.id === data.status).value">
            </Tag>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by status name"
            />
          </template>
        </Column>
        <Column header="Aksi" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
          <template #body="{ data }">
            <Button
              icon="pi pi-eye"
              class="p-button-rounded p-button-info p-2"
              @click="detailData(data.id)"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning ml-1 p-2"
              @click="editData(data.id)"
            />
            <Button
              label="Reset Password"
              icon="pi pi-refresh"
              class="p-button-danger ml-1 py-2"
              @click="confirmResetPassword(data)"
            />
          </template>
        </Column>
      </DataTable>

      <Dialog header="Reset Password" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
          <span>Apakah Anda yakin ingin melanjutkan reset password?</span>
        </div>
        <template #footer>
          <template v-if="!loadingResetPassword">
            <Button label="Tidak" icon="pi pi-times" @click="closeConfirmation" class="p-button-text"/>
            <Button label="Ya" icon="pi pi-check" @click="doResetPassword(store.id)" class="p-button-text" autofocus/>
          </template>
          <ProgressSpinner style="width: 25px; height: 25px;" strokeWidth="8" v-else />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      users: [],
      filters1: null,
      admin_status: [
        { id: 0, value: 'InActive' },
        { id: 1, value: 'Active' },
        { id: 2, value: 'Suspend' },
      ],
      displayConfirmation: false,
      store: {},
      loadingResetPassword: false,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.loadData()
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-admin" });
    },
    editData(id) {
      this.$router.push({ name: "edit-admin", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-admin", params: { id: id } });
    },
    confirmResetPassword(data) {
      this.displayConfirmation = true
      this.store = data
    },
    doResetPassword(adminID) {
      this.loadingResetPassword = true
      this.axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${adminID}/admin/reset-password`).then(response => {
        this.displayConfirmation = false
        this.loadingResetPassword = false
        if (response.status === 200) {
          this.$toast.add({
            severity: 'success',
            closable: true,
            summary: 'Success',
            detail: `Password yang baru telah di kirim ke email ${this.store.email}`,
          })
        } else {
          this.$toast.add({
            severity: 'error',
            closable: true,
            summary: 'Error',
            detail: response.data.message
          })
        }
      })
    },
    loadData() {
      this.axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/admin`).then(response => {
        if (response.status === 200) {
          this.users = response.data.data.users;
          const temp = [];
          this.users.forEach((user, idx) => {
            temp.push({ ...user, no: idx + 1 });
          });
          this.users = temp;
        }
      })
    },
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        phone_number: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        status_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    severity(status) {
      let result = '';
      switch (status) {
        case 0:
          result = 'warning';
          break;
        case 1:
          result = 'success';
          break;
        default:
          result = 'danger';
          break;
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
